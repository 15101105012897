import {Box, Button, Card, CardContent, Grid, Typography} from "@mui/material";

import Rating from "components/rating";

import {PropertySearched} from "types/entities/propertiesSearched";

const ReportCard = ({property, handleClick}: {
    property: PropertySearched,
    handleClick: (propertyId: number) => void
}) => {
    return (
        <Grid item xs={12} sm={4} key={property.id}>
            <Card sx={{minWidth: 275}}>
                <CardContent>
                    <Rating rating={property['report']['overallRating']}/>
                    <Typography variant="h5" component="div"
                                style={{
                                    marginTop: 10,
                                    marginBottom: 30,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',      /* Prevents text from wrapping to a new line */
                                    overflow: 'hidden'
                                }}>
                        {property.propertyInfo.title}
                    </Typography>
                    <Box style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography style={{
                            fontWeight: 'bold',
                            color: '#646cec'
                        }}>{property.propertyInfo.price}</Typography>
                        <Button size="small" onClick={() => handleClick(property.id)}
                                style={{textDecoration: 'underline'}}>View report</Button>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default ReportCard;