import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {Alert, Button, Container, Grid, Snackbar, Typography} from "@mui/material";
import {Box} from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import BaseReport from "../components/BaseReport";
import BaseLayout from "layout/BaseLayout";
import {PropertySearched} from "types/entities/propertiesSearched";
import Footer from "components/Footer";
import Loader from "components/Loader";

import {supabase} from "config/supabaseClient";

import logo from "static/logoWhiteBlue.png";
import {useTranslation} from "react-i18next";

const PublicReport = () => {

    const {t} = useTranslation();

    const {propertyId} = useParams();

    const [property, setProperty] = useState<PropertySearched>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        (async () => {

            // get property
            let {data, error} = await supabase
                .from('PropertySearched')
                .select("*")
                // Filters
                .eq('id', propertyId);

            if (!error) setProperty(data[0] as unknown as PropertySearched);
            setLoading(false);
        })()

    }, [propertyId]);

    const handleClickOnLogo = () => window.open('https://www.nidoscout.com/');

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopyLink = () => {
        const currentPageLink = window.location.href;

        navigator.clipboard.writeText(currentPageLink)
            .then(() => {
                setSnackbarOpen(true); // Show snackbar
            })
            .catch(err => {
                console.error('Failed to copy link: ', err);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <BaseLayout>
            <Container>
                {!loading ? <>
                    <Grid container
                          sx={{
                              marginY: 3,
                          }}
                          rowSpacing={2}
                    >

                        {/*logo and title*/}
                        <Grid item xs={12} sm={8}
                              sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                        >
                            <Typography variant='h3'>
                                {t('reports.publicReport.reportByNido')}
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 0.5,
                                    borderRadius: 0.5,
                                    textAlign: 'center',
                                    marginLeft: 2,
                                    cursor: 'pointer'
                                }}
                                onClick={handleClickOnLogo}
                            >
                                <img src={logo} alt={'logo'} style={{
                                    width: '40px',
                                    height: '40px',
                                    marginRight: '10px'
                                }}/>
                                <Typography style={{
                                    fontWeight: 'bold',
                                    color: 'gray'
                                }}>
                                    Nidoscout.com
                                </Typography>
                            </Box>
                        </Grid>

                        {/* open listing button */}
                        <Grid item xs={12} sm={4}
                              sx={{
                                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
                              }}>
                            {/* copy link */}
                            <Button onClick={handleCopyLink} variant='outlined' sx={{marginRight: 1}}>
                                <ContentCopyIcon
                                    style={{marginRight: 10, cursor: 'pointer'}}
                                />
                                {t('reports.report.copyLink')}
                            </Button>

                            <Snackbar
                                open={snackbarOpen}
                                autoHideDuration={3000}
                                onClose={handleSnackbarClose}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            >
                                <Alert onClose={handleSnackbarClose} severity="success" sx={{width: '100%'}}>
                                    Link copied to clipboard!
                                </Alert>
                            </Snackbar>

                            <Button onClick={() => window.open(property.link)} variant='outlined' sx={{marginRight: 1}}>
                                <OpenInNewIcon
                                    style={{marginRight: 10, cursor: 'pointer'}}
                                />
                                {t('reports.report.seeLink')}
                            </Button>

                        </Grid>
                    </Grid>

                    {!!property &&
                        <Box sx={{marginBottom: '20px'}}>
                            <BaseReport property={property}/>
                        </Box>
                    }
                </> : <Box sx={{marginY: 3}}><Loader/></Box>}
                {/* footer */}
                <Footer/>
            </Container>
        </BaseLayout>

    )
}

export default PublicReport;