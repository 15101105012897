import {useState} from "react";

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Dialog, DialogActions,
    DialogContent,
    Grid,
    Typography
} from "@mui/material";
import {Box} from "@mui/system";

const NewUserComponents = () => {

    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setDialogContent(null);
    };

    const handleGoToIdealista = () => window.open('https://www.idealista.com/');

    return (
        <Container sx={{marginTop: 4}}>
            <Typography variant='h2'>
                Nido Scout guide
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={2} sx={{marginTop: 1}}>
                {/*step by step tutorial*/}
                {
                    [
                        {
                            title: 'How it works',
                            description: 'Check 3 simple steps to get maximum value from our property analysis',
                            backgroundImage: "house1.png",
                            content: (
                                <Container>
                                    <Typography variant='h2'>
                                        Quick tutorial
                                    </Typography>
                                    <Grid container columnSpacing={4} rowSpacing={2} sx={{marginTop: 1}}>
                                        {/*step by step tutorial*/}
                                        {
                                            [
                                                {
                                                    title: 1,
                                                    description: 'Search for a property on Idealista, the largest property portal in Spain.',
                                                    action: () => handleGoToIdealista(),
                                                    actionTitle: 'Go to Idealista'
                                                }, {
                                                title: 1,
                                                description: 'Copy the link of the property you\'re interested in and paste it into the search bar on Nido.'
                                            },
                                                {
                                                    title: 1,
                                                    description: 'Click the "Create Report" button, and that\'s it!'
                                                }
                                            ].map((item, idx) => (
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant='h4'
                                                                gutterBottom>Step {idx + 1}</Typography>
                                                    <Typography>
                                                        {item.description}
                                                    </Typography>
                                                    {
                                                        item.action &&
                                                        <Box sx={{marginTop: '20px'}}>
                                                            <Button onClick={item.action} variant='outlined'
                                                                    color="secondary">
                                                                {item.actionTitle}
                                                            </Button>
                                                        </Box>
                                                    }
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Container>
                            ),
                        }, {
                        title: 'Rating system',
                        description: 'What those stars and numbers actually mean and how to get the most out of it',
                        backgroundImage: "space.png",
                        content: (
                            <Container>
                                <Typography variant='h2' sx={{marginBottom: '15px'}}>
                                    Understanding Ratings
                                </Typography>
                                <Typography>
                                    For each relevant item in the report, we have developed a rating system that scores
                                    items on a scale from 0 to 10, with 10 representing the highest rating.
                                </Typography>
                            </Container>
                        ),
                    },
                        {
                            title: 'How to compare',
                            description: 'You have several favourites? Let\'s compare which property is going to perform better!',
                            backgroundImage: "houses.png",
                            content: (
                                <Container>
                                    <Typography variant='h2' sx={{marginBottom: '15px'}}>
                                        Compare properties
                                    </Typography>
                                    <Grid container columnSpacing={4} rowSpacing={2} sx={{marginTop: 1}}>
                                        {/*step by step tutorial*/}
                                        {
                                            [
                                                {
                                                    title: 1,
                                                    description: 'Go to "Compare Properties" page',
                                                },
                                                {
                                                    title: 1,
                                                    description: 'Select the first property you want to compare',
                                                },
                                                {
                                                    title: 1,
                                                    description: 'Select the second property you want to compare with the previous one'
                                                },
                                                {
                                                    title: 1,
                                                    description: 'Check all the information and charts to compare which one is better'
                                                }
                                            ].map((item, idx) => (
                                                <Grid item xs={12} sm={3}>
                                                    <Typography variant='h4'
                                                                gutterBottom>Step {idx + 1}</Typography>
                                                    <Typography>
                                                        {item.description}
                                                    </Typography>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Container>
                            ),
                        }
                    ].map((item, idx) => (
                        <Grid item xs={12} sm={4} key={idx}>
                            <Card sx={{
                                padding: 1,
                                backgroundImage: `url(${item.backgroundImage})`, // Use the background image from your item object
                                backgroundSize: 'cover', // Ensure the image covers the entire card
                                backgroundPosition: 'center', // Center the image
                                color: 'white', // Adjust text color for better contrast
                                position: 'relative', // Make the card's position relative
                            }}>
                                {/* Dark overlay */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Adjust the opacity as needed
                                        zIndex: 1, // Ensure the overlay is on top of the background
                                    }}
                                />
                                <Box sx={{position: 'relative', zIndex: 2, padding: 1}}>

                                    <CardContent>
                                        <Typography variant='h3' gutterBottom
                                                    sx={{color: 'white'}}>{item.title}</Typography>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            onClick={() => handleOpenDialog(item.content)}
                                            variant="contained"
                                        >
                                            Learn more
                                        </Button>
                                    </CardActions>
                                </Box>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>

            {/* Dialog for custom content */
            }
            <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogContent sx={{paddingTop: 5}}>{dialogContent}</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant="contained" color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}

export default NewUserComponents;