import {Box, Button, Card, CardActions, CardContent, Container, Grid, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {routes} from "config/routes";
import {supabase} from "config/supabaseClient";
import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";
import {LoadingButton} from "@mui/lab";
import UserSearchedProperties from "../lists/UserSearchedProperties";
import {useGetUserSearchedProperties} from "services/propertiesSearched";
import Loader from "components/Loader";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {useTranslation} from "react-i18next";
import ReportCard from "../lists/components/ReportCard";
import {PropertySearched} from "types/entities/propertiesSearched";
import NewUserComponents from "./components/NewUserComponents";

const HomeReports = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const {user} = useAuth();

    const {landlord, isLoading: isLoadingLandlord} = useGetLandlordProfile({userAuthId: user.id})

    const {properties} = useGetUserSearchedProperties({
        landlordId: landlord?.id
    });

    // TODO: this hook is not working properly
    // const {properties: allProperties} = useGetAllSearchedProperties();

    const [link, setLink] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [allProperties, setAllProperties] = useState<PropertySearched[]>()

    // Get initial properties
    useEffect(() => {
        (async () => {
            let {data, error} = await supabase
                .from('PropertySearched')
                .select(`*`)
                .order('id', {ascending: true});

            const properties = data as PropertySearched[];

            setAllProperties(properties);

        })();
    }, []);

    const handleChangeLink = (e: any) => {
        setLink(e.target.value)
    }

    const handleOnSubmit = async () => {

        setIsLoading(true);

        // validate link is from idealista (without forcing http/https or www)
        const idealistaUrlRegex = /^(https?:\/\/)?(www\.)?idealista\.(com|pt|it)\/.*$/;

        // Check if the link is valid
        if (!idealistaUrlRegex.test(link)) {
            alert("Please enter a valid Idealista property link.");
            setIsLoading(false)
            return;
        }

        let property = {};

        // check if property exists in DB
        let {data: propertyResponse, error} = await supabase
            .from('PropertySearched')
            .select("*")
            .eq('link', link)
            .limit(1)

        // if property exists in DB
        if (propertyResponse.length > 0) {

            property = propertyResponse[0];

            // check if user already searched for the property
            let {data: userPropertyResponse, error} = await supabase
                .from('User-PropertySearched')
                .select("*")
                .eq('user', landlord.id)
                .eq('propertySearched', property['id'])

            // if user is first time searching this property
            if (userPropertyResponse.length === 0) {
                // create relation user-property-searched
                const {} = await supabase
                    .from('User-PropertySearched')
                    .insert([
                        {propertySearched: property['id'], user: landlord.id},
                    ])
                    .select()
            }

        }
        // if property is not created in DB, then
        else {

            // 1. Create the propertySearched in DB
            const {data: propertySearchedCreated, error} = await supabase
                .from('PropertySearched')
                .insert([
                    {link: link},
                ])
                .select()

            property = propertySearchedCreated[0];

            // 2. Create relation property - user in DB
            const {} = await supabase
                .from('User-PropertySearched')
                .insert([
                    {propertySearched: property['id'], user: landlord.id},
                ])
                .select()

            // 3. Create report
            const response = await fetch(
                "https://ajzrselqnmiqsgynwkuf.supabase.co/functions/v1/create-property-report",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({urlToScrape: link}), // Send the link as the payload
                }
            );

            const data = await response.json();

            const report = {items: data.response.report, overall: data.response.overallReport};
            const propertyInfo = data.response.propertyInfo.structured;

            const getOverallRating = (report: any) => {
                const validItems = report.items.filter(item => item.rating !== undefined || item.rating !== null);
                const sum = validItems.reduce((acc, item) => acc + item.rating, 0);

                return validItems.length > 0 ? sum / validItems.length : 0;
            };

            // get overall rating
            // update overall rating
            report['overallRating'] = getOverallRating(report);

            // 4. save report in property record
            const {} = await supabase
                .from('PropertySearched')
                .update({
                    report: report,
                    propertyInfo: propertyInfo
                })
                .eq('id', property['id'])
                .select()

        }

        // 5. return or redirect to page with param reportId as url param
        navigate(`/${routes.reports.base}/${routes.reports.property}/${property['id']}`)
    }

    const reportsRemaining = landlord && properties ? (landlord.maxReports - properties.length) : 0;

    const handleUpgradePlan = () => navigate(routes.plans);

    const userHasPaidPlan = landlord && landlord.maxReports > 10;

    const userHasFreeReportReaming = reportsRemaining > 0;

    const handleGoToIdealista = () => window.open('https://www.idealista.com/');

    const demoReport = () => {
        const demoProperty = allProperties[0] as PropertySearched;
        const handleDemoClick = (propertyId) => navigate(`/${routes.reports.base}/${routes.reports.property}/${propertyId}`);

        return (!!demoProperty ? <Container sx={{display: 'flex', flexDirection: 'column', marginTop: '50px'}}>
            <Typography variant='h3' marginBottom={2}>
                Check this demo report
            </Typography>
            <Grid container>
                <Grid item>
                    <ReportCard property={demoProperty} handleClick={handleDemoClick}/>
                </Grid>
            </Grid>
        </Container> : null)

    };

    const renderNewUserComponents = () => <NewUserComponents/>;

    return <Container maxWidth="lg">

        <Card>
            <CardContent>
                <Container style={{justifyContent: 'center', textAlign: 'center', padding: 20}}>
                    <Typography variant='h3' style={{marginLeft: '23px', marginBottom: 20}}>
                        {t('reports.home.title')}
                        {/*Analyze any Idealista listed property in one click*/}
                    </Typography>

                    {/* Go to Idealista */}
                    {/*{!!properties && (properties.length === 0) && <Button*/}
                    {/*    onClick={handleGoToIdealista}*/}
                    {/*    style={{*/}
                    {/*        marginBottom: 10,*/}
                    {/*        color: 'gray'*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Check properties on Idealista*/}
                    {/*</Button>}*/}

                    <Grid
                        container
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '0px 23px',
                        }}
                        spacing={2}
                    >
                        <Grid item xs={12} sm={10}>
                            < TextField
                                label={t('reports.home.placeHolder')}
                                type="text"
                                fullWidth
                                value={link}
                                onChange={handleChangeLink}
                                style={{marginRight: 30}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <LoadingButton variant='contained' onClick={handleOnSubmit} fullWidth style={{height: 53}}
                                           disabled={!link || isLoading || reportsRemaining <= 0 || isLoadingLandlord}
                                           loading={isLoading}
                            >
                                {t('reports.home.createReport')}
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12}>
                            {!isLoadingLandlord ?
                                <>
                                    {
                                        userHasPaidPlan ?
                                            // user has paid plan
                                            <Box style={{display: 'flex', justifyContent: 'center'}}>
                                                <DoneAllIcon style={{color: 'green', marginRight: 4}}/>
                                                <Typography style={{fontWeight: 'bold', color: 'green'}}>
                                                    {/*All set, you are using Premium Plan*/}
                                                    {t('reports.home.allSetPremium')}
                                                </Typography>
                                            </Box>
                                            :
                                            // user has free plan
                                            userHasFreeReportReaming ?

                                                <>
                                                    <Box style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center'
                                                    }}>
                                                        {/* user has free reports remaining */}
                                                        <Typography>
                                                            You are using Free Plan, you
                                                            have 3 free monthly reports.
                                                        </Typography>
                                                        <Typography
                                                            onClick={handleUpgradePlan}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                cursor: 'pointer',
                                                                marginLeft: '4px',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            Discover Premium Plan
                                                        </Typography>
                                                    </Box>
                                                </>
                                                :
                                                // user reach the limit of free report
                                                <Typography
                                                    style={{
                                                        // marginLeft: '4px',
                                                        fontWeight: 'bold',
                                                        color: 'red'
                                                    }}
                                                >
                                                    You have reached the limit of free reports, please upgrade your plan
                                                </Typography>
                                    }
                                    {/* button to upgrade plan */}
                                    {
                                        reportsRemaining <= 0 &&
                                        <Button variant={'contained'} style={{marginTop: 20}}
                                                onClick={handleUpgradePlan}>
                                            Upgrade your plan
                                        </Button>
                                    }
                                </>
                                : <Loader/>}
                        </Grid>
                    </Grid>
                </Container>
            </CardContent>
        </Card>
        {/* loader */}
        {/*{*/}
        {/*    isLoading && <Loader/>*/}
        {/*}*/}

        {renderNewUserComponents()}

        {/* display already built property reports */}
        {
            !!properties && (properties.length > 0) && <Box style={{marginTop: 50}}>

                {/*display already built rerport*/}
                <UserSearchedProperties limit={6} title="Latest reports"/>
            </Box>
        }
    </Container>
}

export default HomeReports;