import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {supabase} from "config/supabaseClient";
import Loader from "components/Loader";
import {Button, Container, Grid, TextField, Typography} from "@mui/material";
import {PropertySearched} from "types/entities/propertiesSearched";
import {Box} from "@mui/system";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import html2canvas from "html2canvas";
import jspdf from "jspdf";

import {routes} from "config/routes";

import BaseReport from "./components/BaseReport";
import Chat from "./components/chat";
import {ChatMessage} from "./types";

type PropertyItemReport = {
    item: string,
    value: string
}

const PropertyReport = () => {

    const reportRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const [property, setProperty] = useState<PropertySearched | undefined>(undefined); // To store the response from the API
    const [loading, setLoading] = useState(true); // Loading state to show loading indicator

    const {propertyId} = useParams();

    const {user} = useAuth();
    const {landlord} = useGetLandlordProfile({userAuthId: user?.id});

    const [openChat, setOpenChat] = useState(false);
    const [chatMessage, setChatMessage] = useState('');
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([{
        isFromUser: false,
        message: 'Hi, I am Scout - your AI powered real estate investing assistant. What would you like to know about this property?'
    }]);

    const fetchReport = async () => {
        // TODO: check when report is demo
        let {data: PropertySearched, error} = await supabase
            .from('PropertySearched')
            .select("*")
            // Filters
            .eq('id', propertyId);

        let {data: UserProperty} = await supabase
            .from('User-PropertySearched')
            .select("*")
            // Filters
            .eq('propertySearched', propertyId)
            .eq('user', landlord?.id)
        ;

        const _property = {...PropertySearched[0], isFavorite: UserProperty[0]['isFavorite']} as PropertySearched;

        setProperty(_property)
        setLoading(false);

    };

    useEffect(() => {

        fetchReport();

    }, [propertyId, landlord]); // Fetch data when component mounts, and when 'link' changes

    // Conditional rendering based on the states
    if (loading) {
        return <Loader/>;
    }

    const handleGoBack = () => {
        navigate(-1);  // -1 means going back to the previous page in the history
    };

    const handleClickOnFavorite = async () => {

        const {data, error} = await supabase
            .from('User-PropertySearched')
            .update({isFavorite: !property.isFavorite})
            .eq('user', landlord.id)
            .eq('propertySearched', property.id)
            .select()

        fetchReport();

    };

    const handleDownloadPdf = async () => {
        if (!reportRef.current) return;

        const canvas = await html2canvas(reportRef.current, {
            scale: 2,
            useCORS: true,
            // backgroundColor: "#f5f5f5", // Optional: Ensures background color is captured
        });

        const imgData = canvas.toDataURL("image/png");
        const pdf = new jspdf("p", "mm", "a4");

        const imgWidth = 190; // A4 width minus 10mm padding on each side
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 10; // Start 10mm down the page to add top padding

        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight); // Left margin of 10mm
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
            position -= pageHeight - 10; // Add bottom margin for new pages
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        pdf.save(`property_report_${propertyId}.pdf`);
    };

    const handlePublicReport = () => {
        window.open(`${window.location.origin}/${routes.reports.base}/${routes.reports.public}/${property.id}`)
    }

    const handleChatReport = async () => {

        // add user message to chat messages
        setChatMessages(prev => ([...prev, {isFromUser: true, message: chatMessage}]));

        // do chat question request
        const response = await fetch(
            "https://ajzrselqnmiqsgynwkuf.supabase.co/functions/v1/chat",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    propertyInfo: property.propertyInfo,
                    propertyReport: property.report,
                    userQuestion: chatMessage
                }),
            }
        );

        // get answer
        const data = await response.json();

        setChatMessages(prev => ([...prev, {isFromUser: false, message: data.response}]));

        //     reset user input message
        setChatMessage('');
    }

    const openChatReport = () => setOpenChat(prev => !prev);

    return (
        <Container>

            <Grid container sx={{marginBottom: '10px'}}>
                <Grid item xs={2}>
                    {/*back button*/}
                    <Button
                        style={{marginBottom: '20px'}}
                        onClick={handleGoBack}
                        variant='outlined'
                    >
                        <ArrowBackIcon/>
                    </Button>
                </Grid>
                <Grid item sm={10}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'stretch', sm: 'center'},
                            justifyContent: {sm: 'flex-end'},
                            gap: 1, // gap between buttons
                            marginTop: {xs: 6, sm: 0}
                        }}
                    >
                        <Button onClick={handleClickOnFavorite} variant='outlined'
                                style={{marginRight: 10, height: 'fit-content'}}>
                            {property.isFavorite ?
                                <FavoriteIcon style={{marginRight: 10, cursor: 'pointer'}}/>
                                :
                                <FavoriteBorderIcon style={{marginRight: 10, cursor: 'pointer'}}/>}

                            {property.isFavorite ? 'Remove from' : 'Add to'} favorites
                        </Button>

                        <Button onClick={() => window.open(property.link)} variant='outlined' sx={{marginRight: 1}}>
                            <OpenInNewIcon
                                style={{marginRight: 10, cursor: 'pointer'}}
                            />
                            View the listing
                        </Button>

                        {/* go to compare property */}
                        <Button onClick={() => navigate(`/${routes.reports.base}/${routes.reports.compare}`, {
                            state: {
                                property
                            }
                        })}
                                variant='outlined'
                                sx={{marginRight: 1}}>
                            {/*<OpenInNewIcon*/}
                            {/*    style={{marginRight: 10, cursor: 'pointer'}}*/}
                            {/*/>*/}
                            Compare
                        </Button>

                        {/*    download as pdf*/}
                        <Button onClick={handleDownloadPdf} variant='outlined'>
                            {/*<OpenInNewIcon*/}
                            {/*    style={{marginRight: 10, cursor: 'pointer'}}*/}
                            {/*/>*/}
                            Download PDF
                        </Button>

                        {/* share public report */}
                        <Button onClick={handlePublicReport} variant='outlined'>
                            {/*<OpenInNewIcon*/}
                            {/*    style={{marginRight: 10, cursor: 'pointer'}}*/}
                            {/*/>*/}
                            Share
                        </Button>

                        {/*<Button onClick={openChatReport} variant='outlined'>*/}
                        {/*    {openChat ? 'Close' : 'Open'} chat*/}
                        {/*</Button>*/}
                        {/*<Button variant="outlined" onClick={handleDownloadPdf} style={{marginBottom: 20}}>*/}
                        {/*    Download PDF*/}
                        {/*</Button>*/}
                    </Box>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={openChat ? 9 : 12}>
                    <div ref={reportRef}>
                        <BaseReport property={property}/>
                    </div>
                </Grid>
                <Grid item xs={openChat ? 3 : 0}>
                    <Chat openChat={openChat} chatMessages={chatMessages} chatMessage={chatMessage}
                          setChatMessage={setChatMessage} handleChatReport={handleChatReport}/>
                </Grid>
            </Grid>
        </Container>
    )
        ;
}

export default PropertyReport;